import React, { useState, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import router from "src/router";
import { CssBaseline, Grid, CircularProgress } from "@mui/material";
import ThemeProvider from "src/theme/ThemeProvider";
import Auth from "src/content/auth/Signin";
import { TokenProvider, useToken } from "src/content/auth/TokenProvider";
import { AshiraseAPIProvider } from "src/content/auth/AshiraseAPIProvider";
import { LoadScript } from "@react-google-maps/api";

function App(): JSX.Element {
  const content = useRoutes(router);

  return (
    <TokenProvider>
      <MainContent content={content as JSX.Element} />
    </TokenProvider>
  );
}

const MainContent: React.FC<{ content: JSX.Element }> = ({ content }) => {
  const { isTokenReady, userData } = useToken();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isTokenReady) {
      setLoading(false);
    }
  }, [isTokenReady]);

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          margin: 0,
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      {userData === null ? (
        <Auth />
      ) : (
        <AshiraseAPIProvider>
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GMAP_KEY ?? ""}>
            <ThemeProvider>
              <CssBaseline />
              {content}
            </ThemeProvider>
          </LoadScript>
        </AshiraseAPIProvider>
      )}
    </>
  );
};

export default App;
