import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { type RouteObject } from "react-router";
import SidebarLayout from "./layouts/SidebarLayout";
import SuspenseLoader from "./components/SuspenseLoader";
import BaseLayout from "./layouts/BaseLayout";

const Loader = (Component: React.ComponentType): React.ComponentType => {
  const WrappedComponent: React.FC = (props: any) => (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

  WrappedComponent.displayName = `Loader(${Component.displayName ?? Component.name ?? "Component"})`;

  return WrappedComponent;
};

const Top = Loader(lazy(async () => await import("src/content/pages/Top")));
const User = Loader(lazy(async () => await import("src/content/pages/User")));
const UserDetails = Loader(
  lazy(
    async () => await import("src/content/pages/User/components/UserDetails"),
  ),
);
const NavigationHistoryDetail = Loader(
  lazy(
    async () =>
      await import(
        "src/content/pages/NavigationHistory/components/NavigationHistoryDetail"
      ),
  ),
);
const MyRoute = Loader(
  lazy(async () => await import("src/content/pages/MyRoute")),
);
const Status404 = Loader(
  lazy(async () => await import("src/content/pages/Status404")),
);
const RouteDetails = Loader(
  lazy(
    async () =>
      await import("src/content/pages/MyRoute/components/RouteDetails"),
  ),
);
const Friend = Loader(
  lazy(async () => await import("src/content/pages/Friend")),
);
const FriendDetail = Loader(
  lazy(
    async () =>
      await import("src/content/pages/Friend/components/FriendDetail"),
  ),
);
const FriendNavigationDetail = Loader(
  lazy(
    async () =>
      await import(
        "src/content/pages/Friend/components/FriendNavigationDetail"
      ),
  ),
);

const routes: RouteObject[] = [
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="top" replace />,
      },
      {
        path: "top",
        element: <Top />,
      },
    ],
  },
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="user" replace />,
      },
      {
        path: "user",
        element: <User />,
      },
      {
        path: "user/:userId",
        element: <UserDetails />,
      },
      {
        path: "navigation_history/:navigationId",
        element: <NavigationHistoryDetail />,
      },
    ],
  },
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="friend" replace />,
      },
      {
        path: "friend",
        element: <Friend />,
      },
      {
        path: "friend/:userId",
        element: <FriendDetail />,
      },
      {
        path: "friend/navigation/:navigationId",
        element: <FriendNavigationDetail />,
      },
    ],
  },
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="my-route" replace />,
      },
      {
        path: "my-route",
        element: <MyRoute />,
      },
      {
        path: "route/:routeId",
        element: <RouteDetails />,
      },
    ],
  },
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
];

export default routes;
