import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useToken } from "./TokenProvider";
import { signOut } from "firebase/auth";
import { auth } from "src/firebase";
import { useNavigate } from "react-router-dom";

interface APIContextType {
  apiRequest: (url: string, options?: RequestInit) => Promise<Response>;
}

const APIContext = createContext<APIContextType | undefined>(undefined);

type APIProviderProps = React.PropsWithChildren<Record<string, unknown>>;
const BASE_URL = process.env.REACT_APP_ASHIRASE_API_DOMAIN;

export const AshiraseAPIProvider = ({
  children,
}: APIProviderProps): JSX.Element => {
  const { token, isTokenReady } = useToken();
  const [pendingRequests, setPendingRequests] = useState<
    Array<() => Promise<void>>
  >([]);
  const navigate = useNavigate();

  const executePendingRequests = useCallback(async () => {
    for (const execute of pendingRequests) {
      await execute();
    }
    setPendingRequests([]);
  }, [pendingRequests]);

  useEffect(() => {
    if (isTokenReady && pendingRequests.length > 0) {
      void executePendingRequests();
    }
  }, [isTokenReady]);

  const apiRequest = useCallback(
    async (endpoint: string, options: RequestInit = {}): Promise<Response> => {
      if (token === null) {
        await signOut(auth);
        navigate("/login");
        return new Response(null, { status: 401 });
      }
      const url = `${BASE_URL}${endpoint}`;

      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        // 強制的に画面をリフレッシュさせる
        window.location.reload();
      }

      return response;
    },
    [isTokenReady, token],
  );

  return (
    <APIContext.Provider value={{ apiRequest }}>{children}</APIContext.Provider>
  );
};

export const callAPI = (): APIContextType => {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("useAPI must be used within an APIProvider");
  }
  return context;
};
